<template>
  <div class="request-a-callback-form">
    <transition name="fade" mode="out-in">
      <div v-show="sent">
        <p>Thanks for contacting us, we will get back to you shortly...</p>
      </div>
    </transition>

    <form class="blue" @submit="onSubmit">
      <input type="text" v-model="names" placeholder="Your Name" />
      <br />
      <br />
      <input
        type="text"
        v-model="email"
        placeholder="Email Address"
      />
      <br />
      <br />
      <textarea
        v-model="message"
        placeholder="Enquiry"
        rows="11"
        cols="1"
        class="form-control"
      >
      </textarea>
      <br />
      <br />
      <button
        data-text="submit"
        type="submit"
        class="btn button btn-primary contactformsub"
      >
        SUBMIT
      </button>
    </form>
  </div>
</template>

<style scoped>
.blue {
  padding-bottom: 5%;
  padding-top: 7%;
  margin-left: -20%;
}

.button {
  border-color: transparent;
  font-size: 1.5rem;
  width: 45%;
  background-color: #00aeef;
  color: #f4f4f6;
}

textarea {
  width: 100%;
  left: 10px;
  float: right;
  height: 130px;
}

.contactformsub {
  width: 250px;
  margin-top: 30px;
  background-color: #CC444A;
  font-size: 1.1rem;
}
.form-control {
  padding: 0.375rem 0.35rem;
  font-size: 1.1rem;
}
input {
  width: 100%;
  font-size: 1.1rem;
  padding: 5px 5px 5px 5px;
}

@media only screen and (max-width: 1440px) {
  textarea {
    margin-top: -15.5%;
  }
}

@media only screen and (max-width: 1024px) {
  textarea {
    margin-top: -19.5%;
  }
}
@media only screen and (max-width: 990px) {
  .blue {
    padding-bottom: 5%;
    padding-top: 7%;
    margin-left: 5%;
    margin-right: 3%;
  }
}

@media only screen and (max-width: 480px) {
  textarea {
    margin-top: 7.5% !important;
    width: 90%;
    float: none;
  }

  input {
    width: 90%;
  }

  .sub {
    margin-left: 5%;
    width: 90%;
  }
  .blue{
    margin-left: 12%;
  }
}
</style>

<script>
import axios from "axios";

export default {
  name: "Form",
  data() {
    return {
      sent: false,
      contact: true,
        names: "",
        email: "",
        phone: "",
        subject: "",
        message: ""
    };
  },
  props: ["contactBlue"],
  methods: {
    onSubmit(e) {
      e.preventDefault();
      const formData = new FormData()
      formData.append('names', this.names)
      formData.append('email', this.email)
      formData.append('phone', this.phone)
      formData.append('subject', this.subject)
      formData.append('message', this.message)
      console.log(formData)
      axios
        .post(
          "https://wp-tpt.ocs-sport.com/wp-json/contact-form-7/v1/contact-forms/410/feedback", formData
        ) 
        .then(response => {
          this.sent = true;
          return response;
          
        });
    }
  }
};
</script>

<template>
    <div class="pad">
            <div class="HeaderSection">
                <b-row>
                    <b-col>
                        <h2 class="FullScheduleTitle" v-if="oomtitle !== ''">
                            {{oomtitle}}
                        </h2>
                        <h2 class="FullScheduleTitle" v-else>
                            Order Of Merit
                        </h2>
                    </b-col>
                    <b-col class="hideOnmob">
                        <b-nav card-header pills>
                            <b-dropdown :text="this.id + ' Season'">
                                <b-dropdown-item
                                v-on:click="changeYear"
                                v-for="(seasons,
                                index) in tm_params.seasons.seasons_entry.slice().reverse()"
                                :key="index"
                                :title="seasons.code"
                                :year="seasons.code"
                                >{{ seasons.desc }}</b-dropdown-item
                                >
                            </b-dropdown>
                        </b-nav>
                    </b-col>
                </b-row>
            </div>
            <table
                class="table table-striped"
            >
            <thead
            >
                <tr>
                <template v-if="isMobile(true)">
                    <th class="posCent">Pos</th>
                </template>
                <template v-else>
                    <th class="posCent">Position</th>
                </template>
                <th style="text-align: left">Name</th>
                <th>Nat</th>
                <template v-if="oomcashType == 'cash'">
                    <th class="hidemob posCent" style="text-transform: capitalize;">Prize Money</th>
                </template>
                <template v-else>
                    <th class="hidemob posCent" style="text-transform: capitalize;">{{oomcashType}}</th>
                </template>
                <th class="posCent">Played</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(oom, index) in data" :key="index">
                    <td class="posCent">{{ oom.pos }}</td>
                    <td style="text-align: left">
                        {{ oom.name }}
                        <span v-if="oom.sponsored === 'Y'">
                            <b-img class="sponsoredImage" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/tartanprotour/2022/05/Staff.png"></b-img>
                        </span>
                    </td>
                    <td>
                        <img class="flag" :src="('https://assets.ocs-sport.com/flags/svg/flag_' + oom.nationality) | lowercase"
                        />
                    </td>
                    <td class="hidemob posCent">{{ oom.oom_value }}</td>
                    <td class="posCent">{{ oom.played }}</td>
                </tr>
            </tbody>
            </table>
    </div>
</template>
<script>
// import axios from 'axios';
import apiCall from "@/services/api.js";
export default {
  name: "OOM",
  props:["config"],
  components: {

  },
  data: function() {
    return {
        oomData: [],
        data: [],
        id: [],
        main_oom:[],
        tm_params: [],
        reports: [],
        course: [],
        season: [],
        white: true,
        
    };
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }
      return value.toLowerCase() + ".svg";
    }
  },
  methods: {
    changeYear: function(event) {
        var id = event.target.getAttribute("title");
        if (this.id > '2024') {
            var main_oom = this.main_oom;
            return (
                (this.id = id),
                (this.main_oom = main_oom),
                apiCall
                .oom(id, main_oom)
                .then(({ data }) => {
                    this.data = data.oom.oom_entry;
                    this.oomcashType = data.oom_type
                    this.oomtitle = data.oom_description;
                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);
                    throw error;
                })
            );   
        } else {
            return (
                (this.id = id),
                apiCall
                .oomOM(id)
                .then(({ data }) => {
                    this.data = data.oom.oom_entry;
                    this.oomcashType = data.oom_type
                    this.oomtitle = data.oom_description;
                    this.loading = false;;
                })
                .catch((error) => {
                    console.log(error);
                    throw error;
                })
            );
        }
    },
    isMobile() {
      if( screen.width <= 500 ) {
        return true;
      }
      else {
        return false;
      }
    },
  },
    async mounted() {
        try {
        // First call, await for call to return
        let first_call = await apiCall.tmParamsBase();
        this.tm_params = first_call.data;
        this.main_oom = first_call.data.tm_params.main_oom
        this.id = first_call.data.tm_params.season_code;
        this.loading = false;
        // Second call, await for call to return
        if (this.id > '2023') {
            let second_call = await apiCall.oom(this.id, this.main_oom);
            this.data = second_call.data.oom.oom_entry;
            this.oomtitle = second_call.data.oom_description;
            this.oomcashType = second_call.data.oom_type;
        } else {
            let second_call = await apiCall.oomOM(this.id);
            this.data = second_call.data.oom.oom_entry;
            this.oomtitle = second_call.data.oom_description;
            this.oomcashType = second_call.data.oom_type;   
        }
        this.loading = false;
        } catch (error) {
        console.log("error in await calls");
        console.log(error);
        throw error;
        }
    },
}
</script>
<style scoped>
.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0)!important;
}
.pad {
    padding: 2% 5%;
}
.sponsoredImage {
  width: 50px;
  margin-left: 10px;
}
::v-deep ul.nav.nav-pills.card-header-pills {
    float: right;
    margin-right: 0px;
}
::v-deep .dropdown-toggle:after {
    margin-left: 10px;
}
::v-deep .btn {
    background-color: rgb(191, 66, 88);
    border-color: rgb(255, 255, 255);
    font-size: 1rem;
    border-radius: 0px;
    width: 300px;
}
.HeaderSection {
    margin-top: 3em;
    margin-bottom: 2em;
}
.posCent {
    text-align: center;
}
.flag {
    width: 30px;
}
td {
    padding: 11px;
    vertical-align: top;
    border-top: 0 solid #dee2e6;
    font-size: 11pt;
    border: 0px!important;
}
::v-deep tr:nth-child(odd) {
    background: rgba(0,0,0,.05);
}
::v-deep .table-bordered thead th, .table-bordered thead td {
    border-bottom-width: 0px;
}
::v-deep thead {
    /* background: pink; */
    /* color: #fff; */
    background-color: #1a2337;
    color: #fff!important;
    padding-bottom: 10px;
    font-size: 10pt;
    font-weight: 100!important;
}
th {
    font-weight: 400;
}
::v-deep .table-bordered th, .table-bordered td {
    border: 0px solid #dee2e6!important;
}
.table-bordered {
    border: 0px solid #dee2e6;
}
</style>
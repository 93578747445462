<template>
  <div>
    <div class="pagePad">
      <b-row>
        <b-col class="emptyCol class" > </b-col>
        <b-col>
          <b-nav-item
            :to="
              '/tournament/' +
                tmparams.code +
                '/' +
                tmparams.tm_params.season_code
            "
            class="FullSchedule"
          >
            Back To Tour Info
          </b-nav-item>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="over">
            <template v-if="tmparams.course_image_file_1.length > 0">
              <div class="bkimageTourn" v-bind:style="{ 'background-image': 'url(' + DEFIMAGESAMS3 + 'tpt' + '/' + 'courses' + '/' + id + '/' + tmparams.course_code + '/' + tmparams.course_image_file_1 + ')' }"></div>
              <!-- <b-img
                :class="[splash]"
                style="width:100%;"
                :src="
                  'https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2020/05/sliderdummycourse.png'
                "
              >
              </b-img> -->
            </template>
            <template v-else>
              <b-img
                :class="[splash]"
                style="width:100%;"
                :src="
                  'https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2020/05/sliderdummycourse.png'
                "
              >
              </b-img>
            </template>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="headPadL">
          <b-row>
            <b-col>
              <h2 class="bold">
                {{ data.full_name }}
              </h2>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <h2 class="courseName">
                {{ data.course_name }}
              </h2>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <h2 class="nonbold">
                {{ data.tourn_dates }}
              </h2>
            </b-col>
          </b-row>
        </b-col>
        <b-col class="headPadR">
          <!-- <b-img class="sponsorsLogo" :src="tmparams.sponsor_logo"></b-img> -->
          <!-- <b-img class="sponsorsLogo" :src="tmparams.sponsor_logo"></b-img> -->
          <b-img
            class="sponsorsLogo"
            :src="tmparams.sponsor_logo"
            onerror="this.src='https://ocs-sport.ams3.cdn.digitaloceanspaces.com/tartanprotour/2021/03/FFOODSTPT.png';"
          />
          <!-- <b-img class="sponsorsLogo" :src="tmparams.sponsor_logo.replace('http://info.tartanprotour.co.uk/', this.DEFIMAGESAMS3 + 'tpt' + '/').replace('https://info.tartanprotour.co.uk/', this.DEFIMAGESAMS3 + 'tpt' + '/')" /> -->
        </b-col>
      </b-row>

      <div class="drop">
        <b-nav card-header pills>
          <b-dropdown
            v-if="Array.isArray(tmparams.reports.reports_entry)"
            :text="title"
            class="dropdownReports"
          >
            <b-dropdown-item
              v-on:click="changeReport"
              v-for="(reports, index) in filterExemptions"
              :key="index"
              :title="reports.report_url"
              :value="reports.report_title"
              v-show="reports.report_title !== 'Live Scoring Monitor'"
              >{{ reports.report_title }}</b-dropdown-item
            >
          </b-dropdown>
          <b-dropdown
            v-else
            text="Please Select a Report"
            class="dropdownReports"
          >
            <b-dropdown-item
              v-on:click="changeReport"
              v-for="(reports, index) in tmparams.reports"
              :key="index"
              :text="title"
              :value="reports.report_title"
              >{{ reports.report_title }}</b-dropdown-item
            >
          </b-dropdown>
        </b-nav>
      </div>
    <div class="mid">
      <div>
        <div class="reports ">
          <div
            v-if="
              this.currentReport.indexOf('tmentry') !== -1 ||
                this.currentReport.indexOf('tmdraw') !== -1 ||
                this.currentReport.indexOf('tmresult') !== -1 ||
                this.currentReport.indexOf('tmoomt') !== -1 ||
                (this.currentReport.indexOf('tmscore') !== -1 &&
                  data.match_play === 'Y')
            "
          >
            <ReportTable
              :data="data"
              :previousData="previousData"
              :home="home"
              :title="title"
              :season="tmparams.tm_params.season_code"
              :code="tmparams.code"
              :config="config"
              :match="tmparams.match_play"
              :team="teamEvent"
              :showGender="showGender"
              :is_qualifier="is_qualifier"
              :stableford="stableford"
              :show_info_web="show_info_web"
              :hide_score_card="hide_score_card"
            />
          </div>
          <div v-else-if="this.currentReport.indexOf('tmscore') !== -1">
            <b-tabs class="tabsLi">
              <template v-if="data.hide_gross_sb == 'Y'">
                <b-tab title="LEADERBOARD" active>
                  <template v-if="data.stableford == 'Y'">
                    <ScoreTeam
                      :data="data"
                      :title="title"
                      :home="false"
                      :config="config"
                      :team="teamEvent"
                      :match="tourinfo.match_play"
                      :code="code"
                      :season="season"
                      :showGender="showGender"
                      :is_qualifier="is_qualifier"
                      :stableford="stableford"
                      :show_info_web="show_info_web"
                      :hide_score_card="hide_score_card"
                    />
                  </template>
                  <template v-else>
                    <ReportTable
                      :data="data"
                      :title="title"
                      :home="false"
                      :config="config"
                      :team="teamEvent"
                      :match="tourinfo.match_play"
                      :code="code"
                      :season="season"
                      :showGender="showGender"
                      :is_qualifier="is_qualifier"
                      :stableford="stableford"
                      :show_info_web="show_info_web"
                      :hide_score_card="hide_score_card"
                    />
                  </template>
                </b-tab>
                <b-tab title="HOLE-BY-HOLE" title-item-class="nonactive HBH">
                  <HBH
                    :data="data"
                    :previousData="previousData"
                    :config="config"
                    :title="title"
                    :showGender="showGender"
                    :show_info_web="show_info_web"
                    :hide_score_card="hide_score_card"
                  />
                </b-tab>
              </template>
              <template v-else>
                <b-tab title="LEADERBOARD" active>
                  <ReportTable
                    tab="N"
                    :data="data"
                    :title="title"
                    :home="false"
                    :config="config"
                    team="N"
                    :match="tourinfo.match_play"
                    :code="code"
                    :season="season"
                    :showGender="showGender"
                    :is_qualifier="is_qualifier"
                    :stableford="stableford"
                    :show_info_web="show_info_web"
                    :hide_score_card="hide_score_card"
                  />
                </b-tab>
                <b-tab title="TEAM" v-if="teamEvent === 'Y'">
                  <template v-if="data.stableford == 'Y'">
                    <ScoreTeam
                      :data="data"
                      :title="title"
                      :home="false"
                      :config="config"
                      :team="teamEvent"
                      :match="tourinfo.match_play"
                      :code="code"
                      :season="season"
                      :showGender="showGender"
                      :is_qualifier="is_qualifier"
                      :stableford="stableford"
                      :show_info_web="show_info_web"
                      :hide_score_card="hide_score_card"
                    />
                  </template>
                  <template v-else>
                    <ReportTable
                      tab="Y"
                      :data="data"
                      :title="title"
                      :home="false"
                      :config="config"
                      :team="teamEvent"
                      :match="tourinfo.match_play"
                      :code="code"
                      :season="season"
                      :showGender="showGender"
                      :is_qualifier="is_qualifier"
                      :stableford="stableford"
                      :show_info_web="show_info_web"
                      :hide_score_card="hide_score_card"
                    />
                  </template>
                </b-tab>
                <b-tab title="HOLE-BY-HOLE" title-item-class="nonactive HBH">
                  <HBH
                    :data="data"
                    :previousData="previousData"
                    :config="config"
                    :title="title"
                    :showGender="showGender"
                    :show_info_web="show_info_web"
                    :hide_score_card="hide_score_card"
                  />
                </b-tab>
              </template>
            </b-tabs>
          </div>
          <div v-else-if="this.currentReport.indexOf('tmlsmon') !== -1">
            <b-tabs class="tabsLi">
              <template v-if="data.hide_gross_sb == 'Y'">
                <b-tab title="Leaderboard" active>
                  <template v-if="data.stableford == 'Y'">
                    <ScoreTeam
                      :data="data"
                      :title="title"
                      :home="false"
                      :config="config"
                      :team="teamEvent"
                      :match="tourinfo.match_play"
                      :code="code"
                      :season="season"
                      :scoreFound="scoreFound"
                      :showGender="showGender"
                      :is_qualifier="is_qualifier"
                      :stableford="stableford"
                      :show_info_web="show_info_web"
                      :hide_score_card="hide_score_card"
                    />
                  </template>
                  <template v-else>
                    <ReportTable
                      :data="data"
                      :title="title"
                      :home="false"
                      :config="config"
                      :team="teamEvent"
                      :match="tourinfo.match_play"
                      :code="code"
                      :season="season"
                      :scoreFound="scoreFound"
                      :showGender="showGender"
                      :is_qualifier="is_qualifier"
                      :stableford="stableford"
                      :show_info_web="show_info_web"
                      :hide_score_card="hide_score_card"
                    />
                  </template>
                </b-tab>
                <b-tab title="HOLE-BY-HOLE" title-item-class="nonactive HBH">
                  <HBH
                    :data="data"
                    :previousData="previousData"
                    :config="config"
                    :title="title"
                    :showGender="showGender"
                    :show_info_web="show_info_web"
                    :hide_score_card="hide_score_card"
                  />
                </b-tab>
              </template>
              <template v-else>
                <b-tab title="Leaderboard" active>
                  <ReportTable
                    tab="N"
                    :data="data"
                    :title="title"
                    :home="false"
                    :config="config"
                    team="N"
                    :match="tourinfo.match_play"
                    :code="code"
                    :season="season"
                    :scoreFound="scoreFound"
                    :showGender="showGender"
                    :is_qualifier="is_qualifier"
                    :stableford="stableford"
                    :show_info_web="show_info_web"
                    :hide_score_card="hide_score_card"
                  />
                </b-tab>
                <b-tab title="TEAM" v-if="teamEvent === 'Y'">
                  <template v-if="data.stableford == 'Y'">
                    <ScoreTeam
                      :data="data"
                      :title="title"
                      :home="false"
                      :config="config"
                      :team="teamEvent"
                      :match="tourinfo.match_play"
                      :code="code"
                      :season="season"
                      :scoreFound="scoreFound"
                      :showGender="showGender"
                      :is_qualifier="is_qualifier"
                      :stableford="stableford"
                      :show_info_web="show_info_web"
                      :hide_score_card="hide_score_card"
                    />
                  </template>
                  <template v-else>
                    <ReportTable
                      tab="Y"
                      :data="data"
                      :title="title"
                      :home="false"
                      :config="config"
                      :team="teamEvent"
                      :match="tourinfo.match_play"
                      :code="code"
                      :season="season"
                      :scoreFound="scoreFound"
                      :showGender="showGender"
                      :is_qualifier="is_qualifier"
                      :stableford="stableford"
                      :show_info_web="show_info_web"
                      :hide_score_card="hide_score_card"
                    />
                  </template>
                </b-tab>
                <b-tab title="Hole by Hole" title-item-class="nonactive HBH">
                  <HBH
                    :tabs="tabs"
                    :data="data"
                    :scores="data.scores.scores_entry"
                    :previousData="previousData"
                    :config="config"
                    :title="title"
                    :showGender="showGender"
                    :show_info_web="show_info_web"
                    :hide_score_card="hide_score_card"
                  />
                </b-tab>
              </template>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div id="footer">
      <Footer :white="white" :config="config" />
    </div>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
import { HBH, ScoreTeam } from "@ocs-software/vue-ocs";
import Footer from "@/components/footer.vue";
import ReportTable from "@/components/reportTable.vue";

export default {
  name: "Reports",
  components: {
    HBH,
    ReportTable,
    Footer,
     ScoreTeam
  },
  props: ["config"],
  data() {
    return {
      tourinfo: [],
      data: [],
      previousData: [],
      tmparams: [],
      drop: "drop",
      currentReport: this.$route.query.url,
      home: false,
      tabs: true,
      title: this.$route.query.title,
      white: true,
      id: this.$route.query.id,
      code: this.$route.query.code
    };
  },
  methods: {
    changeReport: function(event) {
      var report = event.target.getAttribute("title");
      var title = event.target.getAttribute("value");
      this.title = title;
      return (
        (this.currentReport = report),
        apiCall
          .report(this.reportTitle)
          .then(({ data }) => {
            this.data = data;
            this.loading = false;
          })
          .catch(error => {
            console.log(error);
            throw error;
          })
      );
    },
    updateStuff: function() {
      apiCall
        .tmParams(this.id, this.code)
        .then(({ data }) => {
          this.tmparams = data;
          this.tourinfo = data;
          this.loading = false;
        })
        .catch(error => {
          console.log(error);
          throw error;
        });
    }
  },
  computed: {
    filterExemptions: function() {
      return this.tmparams.reports.reports_entry.filter(
        reports_entry => !reports_entry.report_title.includes("Statistics")
      );
    },
    teamEvent: function(team) {
      var array = this.data;
      if (array["team_scores"] !== undefined && array["team_sb"] !== 'Y') team = "Y";
      else team = "N";
      return team;
    },
    reportTitle: function(url) {
      if (this.currentReport.indexOf("tmentry") !== -1) {
        url =
          process.env.VUE_APP_APIFULL_URL +
          this.$route.query.id +
          "/" +
          this.$route.query.id +
          "-" +
          this.$route.query.code +
          "-entry-entries.json?randomadd=" +
          new Date().getTime();
      } else {
        if (this.currentReport.indexOf("tmscore") !== -1) {
          var subRep2 = this.currentReport.lastIndexOf("params=") + 7;
          var indNext2 = this.currentReport.indexOf("~", subRep2);
          var repParams = this.currentReport.substr(
            subRep2,
            indNext2 - subRep2
          );

          if (repParams && repParams.indexOf(".cgi") < 0) {
            url =
              this.config.VUE_APP_APIFULL_URL +
              this.$route.query.id +
              "/" +
              this.$route.query.id +
              "-" +
              this.$route.query.code +
              "-scores-" +
              repParams +
              ".json" +
              "?randomadd=" +
              new Date().getTime();
          } else {
            url =
              this.config.VUE_APP_APIFULL_URL +
              this.$route.query.id +
              "/" +
              this.$route.query.id +
              "-" +
              this.$route.query.code +
              "-scores-latest.json" +
              "?gt=y&randomadd=" +
              new Date().getTime();
          }
        } else if (this.currentReport.indexOf("tmlsmon") !== -1) {
          {
            url =
              this.config.VUE_APP_APIFULL_URL +
              this.$route.query.id +
              "/" +
              this.$route.query.id +
              "-" +
              this.$route.query.code +
              "-scores-latest.json" +
              "?gt=y&randomadd=" +
              new Date().getTime();
          }
        } else if (this.currentReport.indexOf("tmdraw") !== -1) {
          var subRep = this.currentReport.lastIndexOf("round=") + 6;
          var indNext = this.currentReport.indexOf("~", subRep);
          var drawRnd = this.currentReport.substr(subRep, indNext - subRep);
          url =
            this.config.VUE_APP_APIFULL_URL +
            this.$route.query.id +
            "/" +
            this.$route.query.id +
            "-" +
            this.$route.query.code +
            "-draw-R" +
            drawRnd +
            "-draw.json" +
            "?randomadd=" +
            new Date().getTime();
        } else if (this.currentReport.indexOf("tmresult") !== -1) {
          var subRep1 = this.currentReport.lastIndexOf("result=") + 7;
          var indNext1 = this.currentReport.indexOf("~", subRep1);
          var resNum1 = this.currentReport.substr(subRep1, indNext1 - subRep1);
          console.log("resNum === ");
          console.log(resNum1);
          url =
            this.config.VUE_APP_APIFULL_URL +
            this.$route.query.id +
            "/" +
            this.$route.query.id +
            "-" +
            this.$route.query.code +
            "-result-result-" +
            resNum1 +
            ".json" +
            "?randomadd=" +
            new Date().getTime();
        } else {
          url =
            this.config.VUE_APP_APIFULL_URL +
            this.$route.query.id +
            "/" +
            this.$route.query.id +
            "-" +
            this.$route.query.code +
            "-result-result-PF.json" +
            "?randomadd=" +
            new Date().getTime();
        }
      }
      return url;
    }
  },
  created() {
    setInterval(this.updateStuff, 120000);
  },
  async mounted() {
    try {
      // First call, await for call to return
      let first_call = await apiCall.report(this.reportTitle);
      this.data = first_call.data;
      this.loading = false;
      // Second call, await for call to return
      let second_call = await apiCall.tmParams(this.id, this.code);
      this.tmparams = second_call.data;
      this.showGender = second_call.data.show_gender;
      this.DEFIMAGESAMS3 = second_call.data.tm_params.DEFIMAGESAMS3;
      this.tourinfo = second_call.data;
      this.is_qualifier = second_call.data.is_qualifier;
      this.stableford = second_call.data.stableford;
      this.show_info_web = second_call.data.tm_params.show_info_web;
      this.hide_score_card = second_call.data.hide_score_card;
      console.log("this.DEFIMAGESAMS3");
      console.log(this.DEFIMAGESAMS3);
      this.loading = false;
    } catch (error) {
      console.log("error in await calls");
      console.log(error);
      throw error;
    }
  }
};
</script>

<style scoped>
.bkimageTourn {
  height: 300px;
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  background-position: 50%;
}
::v-deep .nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
  margin-bottom: 0 !important;
}
::v-deep h2.title {
  background: transparent !important;
  color: #1a2337 !important;
}
::v-deep h2.title.suppliersText {
  background: transparent !important;
  color: #1a2337 !important;
}
h2.courseName {
  font-weight: 400;
  margin-top: 25px;
  margin-bottom: 10px;
}
.sponsorsLogo {
  float: right;
  width: 200px;
}
.splash {
  width: 100%;
}
h2 {
  font-size: 24px;
}
.bold {
  font-weight: 600;
}
.headPadL {
  /* padding-left: 10%; */
  padding-top: 5%;
}
.headPadR {
  padding-top: 5%;
}
.pagePad {
  padding: 2% 5%;
}
#tourTitle {
  padding: 2%;
}
::v-deep .title {
  padding-left: 2%;
  background-color: #1a2337;
  color: white;
  padding-right: 2%;
  text-align: left;
}
::v-deep .mt-3 {
  margin-top: 0 !important;
}
.over {
  height: 250px;
  overflow: hidden;
  margin-top: 4em;
}
.FullSchedule > a {
  background: #CC444A;
    border: none;
    color: #fff !important;
    padding: 10px 50px 10px 50px;
    float: right;
    margin-top: 3.2em;
    margin-right: 1em;
    font-size: 11pt;
    width: 300px;
}
.FullSchedule > a:hover {
  background: #1a2337;
}
::v-deep .nav-tabs {
  border-bottom: 0px solid #dee2e6;
}
::v-deep .tabsScores > div > ul {
  border-radius: 0px;
}
::v-deep .tabsScores > div > ul > li > a {
  color: #fff !important;
  font-size: 13pt;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 30px;
  padding-right: 30px;
}
::v-deep .tabsScores > div > ul > li {
  background-color: #1a2337;
}
::v-deep .nav-link.active {
  color: white !important;
  background-color: #1C2335 !important;
  border-color: #1C2335 !important;
  height: 100%;
  border-radius: 0px;
}
.drop {
  padding: 35px 10px;
}
::v-deep .dropdown-toggle {
  background-color: #CC444A;
  border-color: #CC444A;
  border-radius: 0;
  width: 300px;
}
::v-deep .dropdown-toggle:after {
  margin-left: 15px !important;
}
::v-deep .dropdown-toggle:hover {
  background-color: #1a2337;
  border-color: #1a2337;
  border-radius: 0;
  width: 300px;
}
::v-deep .dropdown.show > .dropdown-toggle {
  background-color: #1a2337;
  border-color: #1a2337;
  border-radius: 0;
}
.sponsor {
  margin-top: -10%;
  width: 20%;
  padding-left: 10%;
}
::v-deep .nav-link.active {
  color: white !important;
  background-color: #1C2335 !important;

  border-color: #1C2335 !important;
  height: 100%;
}

::v-deep .nav-link {
  color: black !important;

  font-size: 12pt;
}

::v-deep li.nav-item {
  text-align: center;
  margin-right: 5px;
}
::v-deep .mt-3 {
  margin-top: 0 !important;
}
@media only screen and (max-width: 480px) {
  .emptyCol {
    display: none;
  }
  .sponsor {
    margin-top: 2%;
    width: 35%;
    padding-left: 4%;
  }
  .drop {
    padding-left: 3%;
    margin-top: -5%;
  }
  .FullSchedule > a {
    margin-right: 0px;
    width: 100%;
  }
  .sponsorsLogo {
    float: left;
  }
}
</style>

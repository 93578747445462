<template>
  <div class="class">
    <div>
      <News :sidebar='sidebar' :config="config"/>
    </div>
    <div>
      <Footer :white="white" :config="config"/>
    </div>
  </div>
</template>

<script>
import News from '@/components/news.vue'
import Footer from "@/components/footer.vue";

export default {
  name: "posts",
  props: ["config"],
  components: {
   News, Footer
  },
  data(){
    return{
      white:false,
    }
  }
};
</script>
